<template>    
    <v-card flat class="elevation-0">
        <v-card-title>
            <slot name="title">
                2-Factor authorisatie
            </slot>
        </v-card-title>
        <v-card-text>
            <div class="my-2" >
                Voer de code in van uw Authenticator app. 
            </div>
            <v-form @submit.prevent="'true'" ref="form">
                <v-row dense>
                    <v-col>
                        <TextField label="Authorisatie code" :dense="!true"
                            prepend-inner-icon="mdi-security"
                            :rules="[v => !!v || 'Code is verplicht']" 
                            v-model="code" required>
                        </TextField>
                    </v-col>
                </v-row>

                <v-row dense>
                    <Button type="submit" :loading="user.isLoading" block main primary large @click="onChallenge">Ga door</Button>
                </v-row>
                <v-row dense>
                    <v-col align="right" justify="right">
                        <slot name="links">
                            <router-link to="/login" class="text-sm base-link">
                                Terug naar inloggen
                            </router-link>
                        </slot>
                    </v-col>
                </v-row>

            </v-form>
        </v-card-text>
    </v-card>

</template>

<script>
import user from '@app/user'

import TextField from '@controls/input/TextField'
import PasswordField from '@controls/input/PasswordField'
import Button from '@controls/buttons/ActionButton'
import eventbus from "@app/eventbus"

//
// Usage: 
//
//   import TwoFactorForm from '@shared/ui/auth/components/TwoFactorForm'
//
export default {
    name: 'TwoFactorForm',
    components: {
        TextField, 
        PasswordField,
        Button
    },
    data() {
        return {
            code: "",
        }
    },
    props: {
        col: {
            type: [String],
            default: ""
        }
    }, 
    methods: {
        onChallenge: async function() {

            if (!this.$refs.form.validate()) {
                return false;
            }
            var result = await this.user.twoFactorChallenge(this.code);
            if (!result.success) {
                eventbus.snackbar.error({text: result.message});
            } else {
                this.$router.push("/");
            }
        },

    },
    setup() {
        return {
            user: user
        }
    }
}
</script>
