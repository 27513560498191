<template>
    <v-container class="fill-height" fluid>
        <slot></slot>        
        <v-row xalign="center" justify="center" >
            <v-col sm="8" md="6" lg="3" >

                <TwoFactorForm/>

            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>

// @ is an alias to /src
//import Frame from '@shared/ui/main/Frame.vue'
import TwoFactorForm from '@shared/ui/auth/components/TwoFactorForm'

export default {
    name: 'LoginTwoFactor',
    components: {
        TwoFactorForm
    }, 
}
</script>
